import React from "react"
import { chunk } from "../../../util/helper"
import H2 from "../../core/H2"
import P from "../../core/P"

export const MasonryPhotoGrid = ({ MasonryPhotoGrid }) => {
  const { title, subtitle, images, gridSize } = MasonryPhotoGrid
  const slideArr = chunk(images, images.length / gridSize)
  let gridmarfgin = ''
  let maxwidth = ''
  switch (gridSize) {
    case 4:
      gridmarfgin = "mx-2 my-4"
      maxwidth = "mx-6"
      break;
    case 3:
      gridmarfgin = "mx-3 my-6"
      maxwidth = "lg:max-w-7xl md:max-w-xl mx-auto"
      break;
    case 2:
      gridmarfgin = "mx-4 my-8"
      maxwidth = "lg:max-w-7xl md:max-w-xl mx-auto"
      break;
    default:
      break;
  }

  return (
    <div className="bg-transperent mt-20">
      <div className="mx-auto text-center " data-aos="fade-up">
        <div
          className="m-6 mx-auto flex flex-col justify-center text-center items-center"
          data-aos="fade-up"
        >
          {title && <H2 title={title} className=" max-w-4xl" />}
        </div>
        {subtitle && (
          <P
            title={subtitle}
            className="text-shark-400"
          />
        )}
        <div className="hidden md:block lg:block">
          <div
            className={`flex flex-wrap lg:mt-16 md:mt-12 mt-8 ${maxwidth}`}
            data-aos="fade-up"
          >
            {slideArr.map((arr, i) => (
              <div className={`w-1/${slideArr.length}`} key={Math.random()}>
                {arr.map((data, j) => (
                  <div className={`${gridmarfgin}`} key={Math.random()}>
                    <img
                      src={data?.mediaItemUrl}
                      alt={
                        data.altText ? data.altText : "Life at #webmobtech"
                      }
                      title={
                        data.title ? data.title : "Life at #webmobtech"
                      }
                      className="w-full shadow-xl"
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="block md:hidden lg:hidden" data-aos="fade-up">
          <div className="flex flex-wrap lg:mt-16 md:mt-12 mt-8 mx-4">
            {slideArr.map((arr, i) => (
              <>
                {arr.map((data, j) => (
                  <div className="w" key={Math.random()}>
                    <div className="mx-2 my-4">
                      <img
                        src={data?.mediaItemUrl}
                        alt="Life at #webmobtech"
                        title="Life at #webmobtech"
                        className="w-full shadow-xl"
                        loading="lazy"
                      />
                    </div>
                  </div>
                ))}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MasonryPhotoGrid
